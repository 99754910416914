import SwaggerUI from 'swagger-ui'

SwaggerUI({
    dom_id: "#myDomId",
    oauth2RedirectUrl: window.location.origin + "/oauth2-redirect.html",
    url: window.location.origin + "/spec/api.yaml",
    deepLinking: true,
    docExpansion: "none"
});

(async function() {
    let logo = document.getElementById("logo");
    let response = await fetch("/api/v1/branding/API_LOGO");
    if (response.ok) {
        logo.setAttribute("src", await response.json());
    } else {
        logo.setAttribute("src", "/images/FinClear.png")
    }
    let titleResponse = await fetch("/api/v1/branding/API_TITLE_TEXT");
    if (titleResponse.ok) {
        window.document.title = await titleResponse.json();
    } else {
        window.document.title = "FinClear TradeCentre API"
    }
})();


